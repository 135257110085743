import * as contentApi from "@/api/contentApi";

const content = {
  namespaced: true,
  state: {
    blocks: null,
    menus: null
  },
  mutations: {
    resetAllContent(state, payload) {
      state.blocks = payload;
      state.menus = payload;
    },
    setBlocks(state, payload) {
      state.blocks = payload;
    },
    setMenus(state, payload) {
      state.menus = payload;
    }
  },
  actions: {
    resetContent(context) {
      context.commit("resetAllContent", null);
    },
    async updateBlocks(context, payload) {
      const lang = payload.lang;
      await contentApi
        .getBlocks(lang)
        .then(({ data }) => {
          context.commit("setBlocks", data.data);
        })
        .catch(() => {});
    },
    async updateMenus(context, payload) {
      const lang = payload.lang;
      await contentApi
        .getMenus(lang)
        .then(({ data }) => {
          context.commit("setMenus", data.data);
        })
        .catch(() => {});
    }
  },
  getters: {
    getBlocks: state => {
      return state.blocks;
    },
    getMenus: state => {
      return state.menus;
    }
  }
};

export default content;

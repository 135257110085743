<template>
  <div>
    <div class="nav-bar-container">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar
          type="dark"
          variant="primary"
          class="d-none d-lg-block bg-base-green"
          id="top-menu"
          role="top-menu"
          v-if="getMenus"
        >
          <b-navbar-nav class="ml-auto" align="right">
            <li class="nav-item c-white"
                v-for="item in getMenu(menuId)"
                :key="item.id"
            >
              <a v-if="item.url" :href="item.url" class="nav-link">{{ item.label }}</a>
              <span class="nav-link nav-text" v-else>{{ item.label }}</span>
            </li>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
    <div class="nav-bar-container bg-white">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar type="light" variant="faded" class="bg-white" id="main-menu">
          <b-navbar-brand :href="headerLink">
            <img
              src="../assets/images/logo.svg"
              class="d-inline-block align-top d-lg-block d-md-none desktop-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
            <img
              src="../assets/images/logo_mobile.png"
              class="d-inline-block align-top d-lg-none mobile-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-navbar-nav class="float-right navbar-right ml-auto language-nav language-vi">
            <LanguageSwitch />
          </b-navbar-nav>
          <b-navbar-nav class="back-menu-box" id="block-linkatras">
            <b-nav-form>
              <b-button href="https://www.1firstbank.com/vi/en">
                1firstbank.com
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "./LanguageSwitch/Index";
import {mapGetters} from "vuex";

export default {
  name: "SiteHeaderVi",
  components: { LanguageSwitch },
  data: function() {
    return {
      language: 'en',
      headerLink: "/",
      content: null,
      menuId: 'header-right',
      menu: null,
      helpers: this.$helperList,
      linksEs: {
        locator: "Locator",
        contact: "Contact Us",
        investor: "Investor Relations",
        routing: "Routing Number: 221571473"
      }
    };
  },
  computed: {
    ...mapGetters({
      getMenus: 'content/getMenus',
    }),
  },
  methods: {
    updateLanguage() {
      this.language = 'en';
      this.headerLink = "/";
    },
    getMenu(id){
      const menuList = this.getMenus;

      const helpers = this.helpers;
      const menu = menuList.filter(item => {
        return item.field_custom_template === id;
      });
      if(!menu[0]){
        return [];
      }
      const menuBlock = menu[0].items.map(menuItem => {
        return {
          id: menuItem.drupal_internal__id,
          label: menuItem.field_pg_menu_link.title,
          url: helpers.getMenuUrl(menuItem.field_pg_menu_link.uri),
        }
      });

      this.menu = menuBlock;
      return menuBlock;
    }
  },
  watch: {
    $route() {
      this.updateLanguage();
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.site-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-link {
  border-right: 1px solid #fff;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Helvetica", Arial, sans-serif !important;
}
.nav-text{
  padding-right: 0!important;
  &:hover{
    text-decoration: none!important;
  }
}
  .nav-item{
    &:last-child{
      .nav-link{
        border-right: none;
        padding-right: 0;
        margin-right: 0;
      }
      margin-right: 0;
    }
  }
</style>

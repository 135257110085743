import { Trans } from "@/plugins/translation";
import Home from "@/views/Home";
import LayoutVi from "@/layouts/vi";
import LayoutDefault from "@/layouts/default"
import HomeVi from "@/views/HomeVi";
import Thanks from "@/views/Thanks";

export default [
  {
    path: "/vi",
    name: "HomveVi",
    component: LayoutVi,
    children: [
      {
        path: "",
        component: HomeVi
      },
      {
        path: "*",
        component: HomeVi
      }
    ],
  },
  {
    path: "/:lang",
    component: LayoutDefault,
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "thanks-page",
        name: "Thanks page",
        component: Thanks
      },
      {
        path: "",
        name: "Home",
        component: Home
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: "*",
    // eslint-disable-next-line no-unused-vars
    redirect(to) {
      return Trans.getUserSupportedLang();
    }
  }
];

<template>
  <b-container :class="languageChange" class="main-wrapper mt-0 pt-0">
    <!--    submissionId    -->
    <b-row
      class="header margin-05 mb-2 mt-0 thanks-section"
      v-if="thankYouBlock && submissionId"
    >
      <h4
        v-html="helpers.setTextAreaField(thankYouBlock.field_header_title)"
      ></h4>
      <div
        class="thanks-content"
        v-html="helpers.setTextAreaField(thankYouBlock.body)"
      ></div>
    </b-row>
    <b-row
      class="header margin-05 mb-2 mt-0"
      v-if="submissionId === null && formContentBlock != null"
    >
      <h4
        v-html="helpers.setTextAreaField(formContentBlock.field_header_title)"
      ></h4>
    </b-row>
    <b-form
      @submit="onSubmit"
      class="form-box bg-white p-lr-08 text-left pb-3 pt-3 float-left clearfix mb-5"
      v-if="submissionId === null"
    >
      <p class="mb-0 required-lbl"><sup>*</sup>{{ $t("vi.form.leyend") }}</p>
      <h4 class="form-sh">{{ $t("vi.form.header") }}</h4>
      <b-form-row class="pl-lg-3">
        <b-form-group
          id="input-group-1"
          label-for="input-1"
          class="name-field field-size-3 col-12 col-md-7 col-lg-3 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.name") }}
            <sup v-show="formValidations.name.required">*</sup></template
          >
          <b-form-input
            id="input-1"
            v-model="form.name"
            type="text"
            @blur="updateErrors('name')"
            @change="updateErrors('name')"
          ></b-form-input>
          <span class="error required" v-if="formError.name">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label-for="input-2"
          class="init-field field-size-1 col-12 col-md-7 col-lg-1 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.initial") }}
            <sup v-show="formValidations.initial.required">*</sup></template
          >
          <b-form-input
            id="input-2"
            v-model="form.initial"
            type="text"
            @blur="updateErrors('initial')"
            @change="updateErrors('initial')"
          ></b-form-input>
          <span class="error required" v-if="formError.initial">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label-for="input-3"
          class="field-size-5 col-12 col-md-7 col-lg-4 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.last_name") }}
            <sup v-show="formValidations.last_name.required">*</sup></template
          >
          <b-form-input
            id="input-3"
            v-model="form.last_name"
            type="text"
            @blur="updateErrors('last_name')"
            @change="updateErrors('last_name')"
          ></b-form-input>
          <span class="error required" v-if="formError.last_name">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label-for="input-4"
          class="field-size-5 col-12 col-md-7 col-lg-4 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.second_last_name") }}
            <sup v-show="formValidations.second_last_name.required"
              >*</sup
            ></template
          >
          <b-form-input
            id="input-4"
            v-model="form.second_last_name"
            type="text"
            @blur="updateErrors('second_last_name')"
            @change="updateErrors('second_last_name')"
          ></b-form-input>
          <span class="error required" v-if="formError.second_last_name">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
      </b-form-row>
      <b-form-row class="pl-lg-3">
        <b-form-group
          id="input-group-5"
          label-for="input-5"
          class="field-size-3 col-12 col-md-7 col-lg-3 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.place_of_birth") }} <sup>*</sup></template
          >
          <b-form-input
            id="input-5"
            v-model="form.place_of_birth"
            type="text"
            @blur="updateErrors('place_of_birth')"
            @change="updateErrors('place_of_birth')"
          ></b-form-input>
          <span class="error required" v-if="formError.place_of_birth">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
        <b-form-group
          class="col col-lg-1 d-md-none d-lg-block pr-4"
        ></b-form-group>
        <b-form-group
          id="input-group-6"
          label="Initial"
          label-for="input-6"
          class="field-size-5 col-12 col-md-7 col-lg-4 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("form.mobile_number") }}
            <sup v-show="formValidations.mobile_number.required"
              >*</sup
            ></template
          >
          <b-form-input
            id="input-6"
            v-model="form.mobile_number"
            type="text"
            v-mask="'(###) ###-####'"
            @blur="updateErrors('mobile_number')"
            @change="updateErrors('mobile_number')"
          ></b-form-input>
          <span class="error required" v-if="formError.mobile_number">{{
            $t("vi.form.error")
          }}</span>
          <span
            class="error email required"
            v-if="formFormatError.mobile_number"
          >
            {{ $t("vi.form.mobile_number_error") }}
          </span>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          label-for="input-9"
          v-slot="{ ariaDescribedby }"
          class="col-12 col-md-7 col-lg-4 military-group-wp"
        >
          <label id="input-group-9__BV_label_" class="text-left">
            {{ $t("vi.form.military_service") }}
            <sup v-show="formValidations.military_service.required">*</sup>
          </label>
          <div class="row">
            <b-form-radio
              v-model="form.military_service"
              :aria-describedby="ariaDescribedby"
              name="input-9"
              value="yes"
              class="radio-input col-6 pl-5 pr-4"
            >
              <span class="lbl-text">{{
                $t("vi.form.military_service_yes")
              }}</span></b-form-radio
            >
            <b-form-radio
              v-model="form.military_service"
              :aria-describedby="ariaDescribedby"
              name="input-9"
              value="no"
              class="radio-input col-2 pl-5"
              ><span class="lbl-text">{{
                $t("vi.form.military_service_no")
              }}</span></b-form-radio
            >
          </div>
        </b-form-group>
      </b-form-row>
      <b-form-row class="pl-lg-3">
        <b-form-group
          id="input-group-8"
          label-for="input-8"
          class="col-12 col-md-7 col-lg-3 pr-lg-4"
        >
          <template slot="label"
            >{{ $t("vi.form.email") }}
            <sup v-show="formValidations.email.required">*</sup></template
          >
          <b-form-input
            id="input-8"
            v-model="form.email"
            type="email"
            @change="updateErrors('email')"
            @blur="updateErrors('email')"
          ></b-form-input>
          <span class="error required" v-if="formError.email">{{
            $t("vi.form.error")
          }}</span>
          <span class="error email required" v-if="formFormatError.email">
            {{ $t("vi.form.email_error") }}
          </span>
        </b-form-group>
        <b-form-group class="col col-lg-1 d-md-none d-lg-block"></b-form-group>
        <b-form-group
          id="input-group-8-1"
          label-for="input-8-1"
          class="col-12 col-md-7 col-lg-4 pr-lg-4"
        >
          <template slot="label">
            {{ $t("vi.form.branch_of_preference") }}
          </template>
          <b-form-select
            id="input-8-1"
            v-model="form.branch_of_preference"
            :options="helpers.getBranchs()"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{
                $t("vi.form.branch_label")
              }}</b-form-select-option>
            </template>
          </b-form-select>
          <span class="error required" v-if="formError.branch_of_preference">{{
            $t("vi.form.error")
          }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          label-for="input-9"
          class="col-12 col-md-7 col-lg-4"
        >
          <template slot="label">
            {{ $t("vi.form.preferred_contact_time") }}
            <sup v-show="formValidations.preferred_contact_time.required"
              >*</sup
            >
          </template>
          <b-form-select
            id="input-8-1"
            v-model="form.preferred_contact_time"
            :options="helpers.getContactTimeList()"
            @change="updateErrors('preferred_contact_time')"
            @blur="updateErrors('preferred_contact_time')"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{
                $t("vi.form.branch_label")
              }}</b-form-select-option>
            </template>
          </b-form-select>
          <span
            class="error required"
            v-if="formError.preferred_contact_time"
            >{{ $t("vi.form.error") }}</span
          >
        </b-form-group>
      </b-form-row>
      <h4 class="form-sh">{{ $t("vi.form.header_1") }}</h4>
      <b-form-row>
        <div class="address-group col-12 col-md-7 col-lg-6 pl-lg-3">
          <h5 class="mb-0">{{ $t("vi.form.address_header") }}</h5>
          <div class="address-group-content col col-lg-8 pl-0">
            <b-form-group id="input-group-10" label-for="input-10">
              <template slot="label"
                >{{ $t("vi.form.line_1") }}
                <sup v-show="formValidations.line_1.required">*</sup></template
              >
              <b-form-input
                id="input-10"
                v-model="form.line_1"
                type="text"
                @change="updateErrors('line_1')"
                @blur="updateErrors('line_1')"
              ></b-form-input>
              <span class="error required" v-if="formError.line_1">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <b-form-group id="input-group-11" label-for="input-11">
              <template slot="label"
                >{{ $t("vi.form.line_2") }}
                <sup v-show="formValidations.line_2.required">*</sup></template
              >
              <b-form-input
                id="input-11"
                v-model="form.line_2"
                type="text"
                @change="updateErrors('line_2')"
                @blur="updateErrors('line_2')"
              ></b-form-input>
              <span class="error required" v-if="formError.line_2">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <div class="city-state-group row">
              <b-form-group
                id="input-group-12"
                label-for="input-12"
                class="city col-7"
              >
                <template slot="label"
                  >{{ $t("vi.form.city") }}
                  <sup v-show="formValidations.city.required">*</sup></template
                >
                <b-form-select
                  id="input-12"
                  v-model="form.city"
                  :options="helpers.getTowns()"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{
                      $t("vi.form.city_label")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-13"
                label-for="input-13"
                class="state col-4"
              >
                <template slot="label"
                  >{{ $t("vi.form.state") }}
                  <sup v-show="formValidations.state.required">*</sup></template
                >
                <b-form-input
                  id="input-13"
                  v-model="form.state"
                  type="text"
                  maxlength="2"
                  @change="updateErrors('state')"
                  @blur="updateErrors('state')"
                ></b-form-input>
                <span class="error required" v-if="formError.state">{{
                  $t("vi.form.error")
                }}</span>
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-14"
              label-for="input-14"
              class="zip-field-group"
            >
              <template slot="label"
                >{{ $t("vi.form.zip_code") }}
                <sup v-show="formValidations.zip_code.required"
                  >*</sup
                ></template
              >
              <div class="zip-fields row ml-0">
                <b-form-input
                  id="input-14"
                  v-model="form.zip_code"
                  type="text"
                  class="zip-item col-5"
                  @change="updateErrors('zip_code')"
                  @blur="updateErrors('zip_code')"
                  maxlength="5"
                  v-mask="'#####'"
                ></b-form-input>
                <div class="zip-line zip-item col-1">
                  <span class="dash-line">–</span>
                </div>

                <b-form-input
                  id="input-14-1"
                  aria-label="Extended zip code 1"
                  v-model="form.zip_code_1"
                  type="text"
                  class="zip-item col-3"
                  v-mask="'####'"
                  @change="updateErrors('zip_code_1')"
                  @blur="updateErrors('zip_code_1')"
                  maxlength="4"
                ></b-form-input>
              </div>
              <span class="error required" v-if="formError.zip_code">{{
                $t("vi.form.error")
              }}</span>
              <span class="error required" v-if="formError.zip_code_1">{{
                $t("vi.form.error")
              }}</span>
              <span
                class="error zipcode required"
                v-if="formFormatError.zip_code"
              >
                {{ $t("vi.form.zip_code_error") }}
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="address-group col-12 col-md-7 col-lg-6 pl-lg-3">
          <h5 class="mb-0">{{ $t("vi.form.address_header_2") }}</h5>
          <b-form-group
            id="input-group-15-1"
            label-for="input-15-1"
            class="address-check col pl-0"
          >
            <b-form-checkbox
              id="input-15-1"
              v-model="form.pa_check"
              name="input-15-1"
              value="yes"
              unchecked-value="no"
              @change="updatePaAddressValues()"
            >
              <span class="lbl-text">{{ $t("vi.form.pa_check") }}</span>
            </b-form-checkbox>
          </b-form-group>
          <div class="address-group-content col col-lg-8 pl-0">
            <b-form-group id="input-group-15" label-for="input-15">
              <template slot="label"
                >{{ $t("vi.form.pa_line1") }}
                <sup v-show="formValidations.pa_line1.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-15"
                v-model="form.pa_line1"
                type="text"
                @change="updateErrors('pa_line1')"
                @blur="updateErrors('pa_line1')"
                :disabled="form.pa_check === 'yes' ? true : false"
              ></b-form-input>
              <span class="error required" v-if="formError.pa_line1">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <b-form-group id="input-group-16" label-for="input-16">
              <template slot="label"
                >{{ $t("vi.form.pa_line_2") }}
                <sup v-show="formValidations.pa_line_2.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-16"
                v-model="form.pa_line_2"
                type="text"
                @change="updateErrors('pa_line_2')"
                @blur="updateErrors('pa_line_2')"
                :disabled="form.pa_check === 'yes' ? true : false"
              ></b-form-input>
              <span class="error required" v-if="formError.pa_line_2">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <div class="city-state-group row">
              <b-form-group
                id="input-group-17"
                label-for="input-17"
                class="city col-7"
              >
                <template slot="label"
                  >{{ $t("vi.form.pa_city") }}
                  <sup v-show="formValidations.pa_city.required"
                    >*</sup
                  ></template
                >
                <b-form-select
                  id="input-17"
                  v-model="form.pa_city"
                  :options="helpers.getTowns()"
                  :disabled="form.pa_check === 'yes' ? true : false"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{
                      $t("vi.form.city_label")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-18"
                label-for="input-18"
                class="state col-4"
              >
                <template slot="label"
                  >{{ $t("vi.form.pa_state") }}
                  <sup v-show="formValidations.pa_state.required"
                    >*</sup
                  ></template
                >
                <b-form-input
                  id="input-18"
                  v-model="form.pa_state"
                  type="text"
                  maxlength="2"
                  @change="updateErrors('pa_state')"
                  @blur="updateErrors('pa_state')"
                  :disabled="form.pa_check === 'yes' ? true : false"
                ></b-form-input>
                <span class="error required" v-if="formError.pa_state">{{
                  $t("vi.form.error")
                }}</span>
              </b-form-group>
            </div>

            <b-form-group
              id="input-group-19"
              label-for="input-19"
              class="zip-field-group"
            >
              <template slot="label"
                >{{ $t("vi.form.pa_zip_code") }}
                <sup v-show="formValidations.pa_zip_code.required"
                  >*</sup
                ></template
              >
              <div class="zip-fields row ml-0">
                <b-form-input
                  id="input-19"
                  v-model="form.pa_zip_code"
                  type="text"
                  class="zip-item col-5"
                  @change="updateErrors('pa_zip_code')"
                  @blur="updateErrors('pa_zip_code')"
                  maxlength="5"
                  v-mask="'#####'"
                  :disabled="form.pa_check === 'yes' ? true : false"
                ></b-form-input>
                <div class="zip-line zip-item col-1">
                  <span class="dash-line">–</span>
                </div>
                <b-form-input
                  id="input-19-1"
                  v-model="form.pa_zip_code_1"
                  type="text"
                  aria-label="Extended zip code 2"
                  class="zip-item col-3"
                  @change="updateErrors('pa_zip_code_1')"
                  @blur="updateErrors('pa_zip_code_1')"
                  maxlength="4"
                  v-mask="'####'"
                  :disabled="form.pa_check === 'yes' ? true : false"
                ></b-form-input>
              </div>
              <span class="error required" v-if="formError.pa_zip_code">{{
                $t("vi.form.error")
              }}</span>
              <span class="error required" v-if="formError.pa_zip_code_1">{{
                $t("vi.form.error")
              }}</span>
              <span
                class="error zipcode required"
                v-if="formFormatError.pa_zip_code"
              >
                {{ $t("vi.form.zip_code_error") }}
              </span>
            </b-form-group>
          </div>
        </div>
      </b-form-row>
      <h4 class="form-sh">{{ $t("vi.form.header_2") }}</h4>
      <b-form-row>
        <div class="employment-group col-12 col-md-7 col-lg-6">
          <div class="employment-wrapper col col-lg-8">
            <b-form-group id="input-group-20" label-for="input-20">
              <template slot="label"
                >{{ $t("vi.form.occupation") }}
                <sup v-show="formValidations.occupation.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-20"
                v-model="form.occupation"
                type="text"
                @change="updateErrors('occupation')"
                @blur="updateErrors('occupation')"
              ></b-form-input>
              <span class="error required" v-if="formError.occupation">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <b-form-group id="input-group-21" label-for="input-21">
              <template slot="label"
                >{{ $t("vi.form.company_name") }}
                <sup v-show="formValidations.company_name.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-21"
                v-model="form.company_name"
                type="text"
                @change="updateErrors('company_name')"
                @blur="updateErrors('company_name')"
              ></b-form-input>
              <span class="error required" v-if="formError.company_name">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <div class="employment-phone-wp row">
              <b-form-group
                id="input-group-22"
                label-for="input-22"
                class="col-7"
              >
                <template slot="label"
                  >{{ $t("vi.form.company_phone") }}
                  <sup v-show="formValidations.company_phone.required"
                    >*</sup
                  ></template
                >
                <b-form-input
                  id="input-22"
                  v-model="form.company_phone"
                  type="text"
                  v-mask="'(###) ###-####'"
                  @change="updateErrors('company_phone')"
                  @blur="updateErrors('company_phone')"
                ></b-form-input>
                <span class="error required" v-if="formError.company_phone">{{
                  $t("vi.form.error")
                }}</span>
                <span
                  class="error email required"
                  v-if="formFormatError.company_phone"
                >
                  {{ $t("vi.form.mobile_number_error") }}
                </span>
              </b-form-group>
              <b-form-group
                id="input-group-23"
                label-for="input-23"
                class="col-4"
              >
                <template slot="label"
                  >{{ $t("vi.form.ext") }}
                  <sup v-show="formValidations.ext.required">*</sup></template
                >
                <b-form-input
                  id="input-23"
                  v-model="form.ext"
                  type="text"
                  @change="updateErrors('ext')"
                  @blur="updateErrors('ext')"
                  v-mask="'#####'"
                ></b-form-input>
                <span class="error required" v-if="formError.ext">{{
                  $t("vi.form.error")
                }}</span>
              </b-form-group>
            </div>
            <b-form-group id="input-group-24" label-for="input-24">
              <template slot="label"
                >{{ $t("vi.form.mi_estimate") }}
                <sup v-show="formValidations.mi_estimate.required"
                  >*</sup
                ></template
              >
              <div class="mi-estimate row ml-0">
                <b-input-group-append class="col-1 pl-0 pr-0">
                  <b-input-group-text
                    class="bg-transparent font-weight-bold rounded-right-none"
                  >
                    $
                  </b-input-group-text>
                </b-input-group-append>
                <b-form-input
                  id="input-24"
                  v-model.lazy="form.mi_estimate"
                  type="text"
                  class="col-5 ml-1 rounded-left-none"
                  @change="updateErrors('mi_estimate')"
                  @blur="updateErrors('mi_estimate')"
                  maxlength="9"
                  v-money="money"
                ></b-form-input>
                <span class="error required" v-if="formError.mi_estimate">{{
                  $t("vi.form.error")
                }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="address-group col-12 col-md-7 col-lg-6 pl-lg-3">
          <h5 class="mb-0">{{ $t("vi.form.address_header_3") }}</h5>
          <div class="address-group-content col col-lg-8 pl-0">
            <b-form-group id="input-group-25" label-for="input-25">
              <template slot="label"
                >{{ $t("vi.form.ca_line_1") }}
                <sup v-show="formValidations.ca_line_1.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-25"
                v-model="form.ca_line_1"
                type="text"
                @change="updateErrors('ca_line_1')"
                @blur="updateErrors('ca_line_1')"
              ></b-form-input>
              <span class="error required" v-if="formError.ca_line_1">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <b-form-group id="input-group-26" label-for="input-26">
              <template slot="label"
                >{{ $t("vi.form.ca_line_2") }}
                <sup v-show="formValidations.ca_line_2.required"
                  >*</sup
                ></template
              >
              <b-form-input
                id="input-26"
                v-model="form.ca_line_2"
                type="text"
                @change="updateErrors('ca_line_2')"
                @blur="updateErrors('ca_line_2')"
              ></b-form-input>
              <span class="error required" v-if="formError.ca_line_2">{{
                $t("vi.form.error")
              }}</span>
            </b-form-group>
            <div class="city-state-group row">
              <b-form-group
                id="input-group-27"
                label-for="input-27"
                class="city col-7"
              >
                <template slot="label"
                  >{{ $t("vi.form.ca_city") }}
                  <sup v-show="formValidations.ca_city.required"
                    >*</sup
                  ></template
                >
                <b-form-select
                  id="input-27"
                  v-model="form.ca_city"
                  :options="helpers.getTowns()"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{
                      $t("vi.form.city_label")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-28"
                label-for="input-28"
                class="state col-4"
              >
                <template slot="label"
                  >{{ $t("vi.form.ca_state") }}
                  <sup v-show="formValidations.ca_state.required"
                    >*</sup
                  ></template
                >
                <b-form-input
                  id="input-28"
                  v-model="form.ca_state"
                  type="text"
                  maxlength="2"
                  @change="updateErrors('ca_state')"
                  @blur="updateErrors('ca_state')"
                ></b-form-input>
                <span class="error required" v-if="formError.ca_state">{{
                  $t("vi.form.error")
                }}</span>
              </b-form-group>
            </div>

            <b-form-group
              id="input-group-29"
              label-for="input-29"
              class="zip-field-group"
            >
              <template slot="label"
                >{{ $t("vi.form.ca_zip_code") }}
                <sup v-show="formValidations.ca_zip_code.required"
                  >*</sup
                ></template
              >
              <div class="zip-fields row ml-0">
                <b-form-input
                  id="input-29"
                  v-model="form.ca_zip_code"
                  type="text"
                  class="zip-item col-5"
                  @change="updateErrors('ca_zip_code')"
                  @blur="updateErrors('ca_zip_code')"
                  maxlength="5"
                  v-mask="'#####'"
                ></b-form-input>
                <div class="zip-line zip-item col-1">
                  <span class="dash-line">–</span>
                </div>
                <b-form-input
                  id="input-29-1"
                  v-model="form.ca_zip_code_1"
                  type="text"
                  class="zip-item col-3"
                  aria-label="Extended zip code 3"
                  @change="updateErrors('ca_zip_code_1')"
                  @blur="updateErrors('ca_zip_code_1')"
                  maxlength="4"
                  v-mask="'####'"
                ></b-form-input>
              </div>
              <span class="error required" v-if="formError.ca_zip_code">{{
                $t("vi.form.error")
              }}</span>
              <span class="error required" v-if="formError.ca_zip_code_1">{{
                $t("vi.form.error")
              }}</span>
              <span
                class="error zipcode required"
                v-if="formFormatError.ca_zip_code"
              >
                {{ $t("vi.form.zip_code_error") }}
              </span>
            </b-form-group>
          </div>
        </div>
      </b-form-row>
      <h4 class="form-sh">{{ $t("vi.form.header_3") }}</h4>
      <b-form-row class="ml-0 mr-0" v-if="formContentBlock">
        <div
          class="description"
          v-html="helpers.setTextAreaField(formContentBlock.field_bottom_text)"
        ></div>

        <b-form-group
          id="input-group-30"
          label-for="input-30"
          class="clearfix terms-field"
        >
          <b-form-checkbox
            id="input-30"
            v-model="form.terms_conditions"
            name="input-30"
            value="accepted"
            unchecked-value=""
            @change="updateErrors('terms_conditions')"
          >
            <span class="label-text lbl-text ">
              {{ $t("vi.form.terms_conditions") }}
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group class="recaptcha-box">
          <vue-recaptcha
            :sitekey="captchaKey"
            :loadRecaptchaScript="true"
            @verify="onVerify"
          ></vue-recaptcha>
        </b-form-group>
      </b-form-row>
      <b-button
        class="float-left clearfix btn btn-submit"
        type="submit"
        variant="primary"
        :class="{ submit: submitting }"
        :disabled="!formIsReady || submitting === true"
      >
        <b-spinner class="primary" label="text left" small></b-spinner>
        {{ $t("vi.form.btn") }}</b-button
      >
    </b-form>
    <modal name="startLoad" id="startLoad" :clickToClose="false">
      <div class="d-flex justify-content-center mb-3 vh-100" align-v="center">
        <img src="/img/loading.gif" class="spinner" />
      </div>
    </modal>
    <modal
      name="codeMessage"
      id="codeMessage"
      :clickToClose="false"
      width="295px"
      height="152px"
    >
      <div class="modal-dialog-speed-bump">
        <div class="modal-content-wrapper">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              @click="$modal.hide('speedBump')"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div class="body-content">
              <p>
                {{ $t("vi.form.codeSend") }}
              </p>
            </div>
            <div class="body-footer">
              <a class="btn btn-open" @click="$modal.hide('codeMessage')">Ok</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <speed-bump v-if="isReady" />
  </b-container>
</template>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer
></script>
<script>
import VueRecaptcha from "vue-recaptcha";
import speedBump from "@/components/sections/speedBump";
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { postWebForm } from "@/api/form";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  directives: {
    mask,
    money: VMoney
  },
  data() {
    return {
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 2
      },
      robot: false,
      captchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
      sendingCode: false,
      formIsReady: false,
      templateName: "Home",
      language: "en",
      enLang: "en",
      content: null,
      helpers: this.$helperList,
      isReady: false,
      form: {
        webform_id: "kis_vi_deposits_lead_form",
        name: "",
        initial: "",
        last_name: "",
        second_last_name: "",
        place_of_birth: "",
        mobile_number: "",
        security_code: "",
        email: "",
        military_service: "",
        preferred_contact_time: null,
        line_1: "",
        line_2: "",
        city: null,
        state: "",
        zip_code: "",
        zip_code_1: "",
        pa_check: "",
        pa_line1: "",
        pa_line_2: "",
        pa_city: null,
        pa_state: "",
        pa_zip_code: "",
        pa_zip_code_1: "",
        occupation: "",
        company_name: "",
        company_phone: "",
        ext: "",
        mi_estimate: "",
        ca_line_1: "",
        ca_line_2: "",
        ca_city: null,
        ca_state: "",
        ca_zip_code: "",
        ca_zip_code_1: "",
        terms_conditions: "",
        campaign: "",
        branch_of_preference: null
      },
      formError: {
        name: "",
        initial: "",
        last_name: "",
        second_last_name: "",
        place_of_birth: "",
        mobile_number: "",
        email: "",
        military_service: "",
        line_1: "",
        line_2: "",
        city: null,
        state: "",
        zip_code: "",
        zip_code_1: "",
        pa_check: "",
        pa_line1: "",
        pa_line_2: "",
        pa_city: null,
        pa_state: "",
        pa_zip_code: "",
        pa_zip_code_1: "",
        occupation: "",
        company_name: "",
        company_phone: "",
        ext: "",
        mi_estimate: "",
        ca_line_1: "",
        ca_line_2: "",
        ca_city: null,
        ca_state: "",
        ca_zip_code: "",
        ca_zip_code_1: "",
        terms_conditions: "",
        campaign: "",
        branch_of_preference: ""
      },
      formFormatError: {
        email: false,
        mobile_number: false,
        zip_code: false,
        pa_zip_code: false,
        ca_zip_code: false,
        company_phone: false
      },
      formValidations: {
        name: {
          required: true
        },
        initial: {
          required: false
        },
        last_name: {
          required: true
        },
        second_last_name: {
          required: false
        },
        place_of_birth: {
          required: true
        },
        mobile_number: {
          required: true
        },
        email: {
          required: true
        },
        military_service: {
          required: true
        },
        preferred_contact_time: {
          required: false
        },
        line_1: {
          required: true
        },
        line_2: {
          required: false
        },
        city: {
          required: true
        },
        state: {
          required: true
        },
        zip_code: {
          required: true
        },
        zip_code_1: {
          required: false
        },
        pa_check: {
          required: false
        },
        pa_line1: {
          required: true
        },
        pa_line_2: {
          required: false
        },
        pa_city: {
          required: true
        },
        pa_state: {
          required: true
        },
        pa_zip_code: {
          required: true
        },
        pa_zip_code_1: {
          required: false
        },
        occupation: {
          required: true
        },
        company_name: {
          required: false
        },
        company_phone: {
          required: false
        },
        ext: {
          required: false
        },
        mi_estimate: {
          required: false
        },
        ca_line_1: {
          required: false
        },
        ca_line_2: {
          required: false
        },
        ca_city: {
          required: false
        },
        ca_state: {
          required: false
        },
        ca_zip_code: {
          required: false
        },
        ca_zip_code_1: {
          required: false
        },
        terms_conditions: {
          required: true
        },
        campaign: {
          required: false
        },
        branch_of_preference: {
          required: false
        }
      },
      isValidCode: true,
      show: true,
      submitting: false,
      submissionId: null,
      blocksBase: null,
      menusBases: null,
      thankYouBlock: null,
      formContentBlock: null
    };
  },
  components: {
    speedBump,
    VueRecaptcha
  },
  async created() {
    this.addLangAttribute();
    this.resetStates();
    await this.setMenus();
    await this.setBlocks();
    await this.setCache();
  },
  computed: {
    ...mapGetters({
      getMenus: "content/getMenus",
      getBlocks: "content/getBlocks"
    }),
    languageChange() {
      return "en";
    }
  },
  methods: {
    onVerify(response) {
      if (response) this.robot = true;

      this.checkFormIsReady();
    },
    resetStates() {
      const contentCache = this.$cookies.get("fl_deposit_leads_content");
      if (contentCache != "1") {
        this.$store.dispatch("content/resetContent");
      }
    },
    async setBlocks() {
      this.blocksBase = this.getBlocks;

      if (this.blocksBase == null) {
        await this.$store
          .dispatch("content/updateBlocks", { lang: this.language })
          .finally(() => {
            this.blocksBase = this.getBlocks;
            this.setThankYouBlock();
            this.setFormBlock();
          });
      } else {
        this.setThankYouBlock();
        this.setFormBlock();
      }
    },
    async setMenus() {
      this.menusBase = this.getMenus;

      if (this.menusBase == null) {
        await this.$store
          .dispatch("content/updateMenus", { lang: this.language })
          .finally(() => {
            this.menusBase = this.getMenus;
          });
      }
    },
    setFormBlock() {
      const id = "form-content";
      const block = this.blocksBase.filter(item => {
        return item.field_custom_template === id;
      });
      if (block[0]) {
        this.formContentBlock = block[0];
      }
    },
    setThankYouBlock() {
      const id = "thanks-page";
      const block = this.blocksBase.filter(item => {
        return item.field_custom_template === id;
      });
      if (block[0]) {
        this.thankYouBlock = block[0];
      }
    },
    setCache() {
      const cacheTime = process.env.VUE_APP_HDRUPAL_VUEX_CACHE;
      this.$cookies.set("fl_deposit_leads_content", 1, cacheTime);
    },
    updatePaAddressValues() {
      const checkStatus = this.form.pa_check;
      this.form.pa_line1 = "";
      this.form.pa_line_2 = "";
      this.form.pa_city = "";
      this.form.pa_state = "";
      this.form.pa_zip_code = "";
      this.form.pa_zip_code_1 = "";
      if (checkStatus === "yes") {
        this.form.pa_line1 = this.form.line_1;
        this.form.pa_line_2 = this.form.line_2;
        this.form.pa_city = this.form.city;
        this.form.pa_state = this.form.state;
        this.form.pa_zip_code = this.form.zip_code;
        this.form.pa_zip_code_1 = this.form.zip_code_1;
      }
    },
    updateErrors(e) {
      const value = this.form[e];
      const zipCode = ["pa_zip_code", "zip_code", "ca_zip_code"];
      const phones = ["company_phone", "mobile_number"];
      const pAddress = [
        "line_1",
        "line_2",
        "city",
        "state",
        "zip_code",
        "zip_code_1"
      ];
      if (pAddress.includes(e) && this.form.pa_check === "yes") {
        this.updatePaAddressValues();
      }
      if (this.formValidations[e].required) {
        if (!value) {
          this.formError[e] = true;
          this.formFormatError[e] = false;
        } else {
          this.formError[e] = false;
          this.formFormatError[e] = false;
        }
      }

      if (e === "email" && value) {
        this.formFormatError[e] = !this.validEmail(value);
      }
      if (phones.includes(e) && value) {
        this.formFormatError[e] = !this.validPhone(value);
      }

      if (zipCode.includes(e) && value) {
        this.formFormatError[e] = !(value.length === 5);
      }
      this.checkFormIsReady();
    },
    checkFormIsReady() {
      let allRequiredSet = true;
      let allFormatErrorsClean = true;
      let allFormErrorClean = true;
      Object.keys(this.formValidations).forEach(key => {
        if (this.formValidations[key].required) {
          if (!this.form[key]) {
            allRequiredSet = false;
          }
        }
      });
      Object.keys(this.formError).forEach(key => {
        if (this.formError[key]) {
          allFormErrorClean = false;
        }
      });
      Object.keys(this.formFormatError).forEach(key => {
        if (this.formFormatError[key]) {
          allFormatErrorsClean = false;
        }
      });
      this.formIsReady =
        allRequiredSet &&
        allFormatErrorsClean &&
        allFormErrorClean &&
        this.robot;
    },
    validPhone(phone) {
      // console.log("Validate phone", phone);
      const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      return re.test(phone);
    },
    validEmail(email) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getContent() {
      this.isReady = true;
    },
    addLangAttribute() {
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
    },
    removeSpecialChars(str) {
      if (str) {
        return str
          .replace(/(?!\w|\s)./g, "")
          .replace(/\s+/g, " ")
          .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2");
      }

      return "";
    },
    addCampaingData() {
      const utmCampaing = this.$route.query.utm_campaign;
      const campaign = this.removeSpecialChars(utmCampaing);
      this.form.campaign = campaign;
    },
    onSubmit(event) {
      if (this.robot) {
        this.submitting = true;
        this.addCampaingData();
        this.sendData();
      }

      event.preventDefault();
    },
    async sendData() {
      const submitValues = this.form;
      const lang = "en";
      this.submitting = true;
      const postResponse = await postWebForm(submitValues, lang)
        .then(({ data }) => {
          if (data.sid) {
            this.submissionId = data.sid;
            return true;
          }
          this.submissionId = null;
          return false;
        })
        .catch(() => {
          this.submissionId = null;
          return false;
        })
        .finally(() => {
          this.submitting = false;
        });
      if (postResponse) {
        // console.log("Send TRUE");
      } else {
        // console.log("Send FALSE");
      }
    }
  },
  watch: {
    $route() {
      this.language = "en";
      this.getContent();
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.error {
  font-size: 12pt;
  font-style: italic;
  color: red;
}
form {
  font-family: "Helvetica", Arial, sans-serif !important;
  font-size: 13px;
}
sup {
  color: red;
}

.btn {
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-appearance: button;
  cursor: pointer;
  min-height: 36px !important;
  border-radius: 4px;
  color: #fff;
  transition-property: border-radius;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  opacity: 1;
  outline: transparent !important;
  border: 1px solid #006630;
  background-color: #006630 !important;
  font-weight: bold;
  span {
    display: none;
  }
  &.bt-code {
    font-size: 14px;
    -webkit-appearance: button-bevel;
  }
  &.submit {
    span {
      display: inline-block;
    }
  }
  &:hover {
    border-radius: 20px;
    border: 1px solid #006830;
    background-color: #006830 !important;
    opacity: 0.95;
  }
  &.disabled {
    opacity: 0.65;
    box-shadow: none;
    cursor: not-allowed;
  }
  &.btn-submit {
    width: 250px;
  }
}
label {
  font-weight: 300;
  margin-bottom: 5px;
}
.custom-control-label {
  &:before {
    width: 0.8rem !important;
    height: 0.8rem !important;
  }
}
.lbl-text {
  padding-top: 4px;
  display: inline-block;
  font-size: 13px;
}
.lbl-wrapper-sec {
  position: relative;
  padding-top: 10px;
  display: block;
  width: 100%;
  float: left;
  height: 25px;
  .lbl-wrapper-code {
    position: absolute;
    width: 175px;
    left: 0;
    top: 0;
  }
}
p {
  margin: 0 0 10px;
}
h4 {
  font-weight: bold;
  font-size: 18px;
}
h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.1;
  padding-bottom: 7px;
}
.custom-control-label {
  font-size: 15px;
}
.form-sh {
  margin-top: 10px;
  font-weight: 500;
  color: #006630;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}
.form-row {
  margin-top: 5px;
  margin-bottom: 5px;
}
.zip-line {
  padding-left: 9px;
  padding-top: 7px;
}
.wait-connection {
  min-height: 80vh;
}
</style>

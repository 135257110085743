import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/i18n";
import AxiosAPI from "./services/axios";
import interceptors from "@/utils/interceptors";
import store from "./store";
import helperList from "@/utils/helpers";
import VModal from "vue-js-modal";
import VueGtag from "vue-gtag";
import vue_cookies from "vue-cookies";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./assets/scss/main.scss";

Vue.use(
  VueGtag,
  {
    config: { id: "GTM-PQMVVXZ" }
  },
  router
);

Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
    scrollable: true
  }
});

Vue.use(vue_cookies);

Vue.config.productionTip = false;

Vue.prototype.$http = AxiosAPI;
Vue.prototype.$store = store;
Vue.prototype.$helperList = helperList;
interceptors();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

let app;
if (!app) {
  app = new Vue({
    el: "#app",
    i18n,
    router,
    created() {},
    render: h => h(App)
  });
}

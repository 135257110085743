<template>
  <div>
    <!-- Footer -->
    <b-container role="footer" class="footer-wrapper" fluid="flex">
      <div class="footer footer-bottom container-lg" v-if="getBlocks">
        <div class="region region-footer-bottom" v-if="getBlock(blockId)">
          <section class="copyright-block block">
            <div class="body body-footer">
              <div class="footer__bar1">
                <p class="copyright" v-html="helpers.setTextAreaField(block.body)"></p>
              </div>
            </div>
          </section>
          <section class="footer-icon-block block">
            <div class="field field--name-body field--type-text-with-summary">
              <p>
                <img
                        v-if="block.field_image.uri"
                  role="img"
                  :srcset="helpers.getFullImagePath(block.field_image.uri)"
                  :src="helpers.getFullImagePath(block.field_image.uri.url)"
                />
                <img
                        v-if="block.field_image_2.uri"
                  role="img"
                  :srcset="helpers.getFullImagePath(block.field_image_2.uri)"
                  :src="helpers.getFullImagePath(block.field_image_2.uri.url)"
                />
                <img
                        v-if="block.field_image_3.uri"
                  role="img"
                  :srcset="helpers.getFullImagePath(block.field_image_3.uri)"
                  :src="helpers.getFullImagePath(block.field_image_3.uri.url)"
                />
              </p>
            </div>
          </section>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SiteFooterVi",
  data() {
    return {
      blocksBase: null,
      blockId: 'footer-block',
      block: null,
      language: 'en',
      helpers: this.$helperList,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      getBlocks: 'content/getBlocks',
    }),
  },
  methods: {
    getBlock(id){
      const blockList = this.getBlocks;
      const block = blockList.filter(item => {
        return item.field_custom_template === id;
      });
      if(!block[0]){
        return false;
      }
      this.block = block[0];

      return true;
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
    }
  }
};
</script>
<style scoped lang="scss">
.field--name-body {
  text-align: left;
}
.footer-wrapper .footer-bottom {
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>

<template>
  <div :class="languageChange">
    <div class="section-wrapper section-content" id="main-content">
      <div class="region region-content"></div>
    </div>
    <speed-bump v-if="isReady" />
    <!--    <speed-bump v-if="isReady && language === 'en'"/>-->
  </div>
</template>

<script>
import speedBump from "@/components/sections/speedBump";
export default {
  name: "Thanks",
  data() {
    return {
      templateName: "Thanks",
      language: this.$i18n.locale,
      content: null,
      helpers: this.$helperList,
      isReady: false
    };
  },
  components: {
    speedBump
  },
  created() {
    this.getContent();
  },
  computed: {
    languageChange() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getContent() {
      console.log("CONTENT DATA");
      const thisLocal = this;
      setTimeout(function() {
        thisLocal.isReady = true;
      }, 2000);
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getContent();
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.wait-connection {
  min-height: 80vh;
}
</style>

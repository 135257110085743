<template>
  <div id="app">
    <a href="#main" class="skip-link screen-reader-text">Skip to content</a>
    <SiteHeaderVi />
    <div>
      <main id="main" role="main" class="container-xl">
        <router-view />
      </main>
    </div>
    <!--    Site Footer   -->
    <div class="footer-wrapper-container">
      <SiteFooterVi />
    </div>
    <!--    Site Footer END -->
  </div>
</template>
<script>
import SiteHeaderVi from "@/components/HeaderVi";
import SiteFooterVi from "@/components/FooterVi";
export default {
  name: "Vi",
  components: {
    SiteFooterVi,
    SiteHeaderVi
  }
};
</script>
<template>
  <div id="app">
    <a href="#main" class="skip-link screen-reader-text">Skip to content</a>
    <SiteHeader />
    <div>
      <main id="main" role="main" class="container-xl">
        <router-view />
      </main>
    </div>
    <!--    Site Footer   -->
    <div class="footer-wrapper-container">
      <SiteFooter />
    </div>
    <!--    Site Footer END -->
  </div>
</template>
<script>
import SiteHeader from "@/components/Header";
import SiteFooter from "@/components/Footer";
export default {
  name: "Default",
  components: {
    SiteFooter,
    SiteHeader
  }
};
</script>
<template>
  <b-nav-form>
    <b-button
      v-for="(lang, index) in supportedLanguages"
      :key="index + 222"
      class="language-link mr-sm-3 ml-1"
      :class="{ 'is-selected': isCurrentLanguage(lang) }"
      href="#"
      @click="changeLanguage"
      :data-language="lang"
    >
      {{ langLabel(lang) }}
    </b-button>
  </b-nav-form>
</template>

<script>
import { Trans } from "@/plugins/translation";
export default {
  name: "LanguageSwitch",
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages;
    },
    currentLanguage() {
      return Trans.currentLanguage;
    }
  },
  methods: {
    langLabel(lang) {
      if (lang === "en") {
        return "English";
      }
      return "Español";
    },
    changeLanguage(e) {
      const lang = e.target.dataset.language;
      const to = this.$router.resolve({ params: { lang } });
      e.preventDefault();
     
        return Trans.changeLanguage(lang).then(() => {
       
        // let prevLang = "es";
        // if (lang === "es") {
        //   prevLang = "en";
        // }
        // const prevPath = "/" + prevLang + "/" + to.location.params.general_path;
        // const prevUrlData = this.$store.getters.getUrlData({
        //   lang: prevLang,
        //   path: prevPath
        // });
        // if (prevUrlData[0]) {
        //   const newUrlData = this.$store.getters.getUrlDataByUUID({
        //     uuid: prevUrlData[0].uuid,
        //     lang: lang
        //   });
        //   if (newUrlData[0]) {
        //     this.$router.push({ path: newUrlData[0].uri });
        //   } else {
        //     this.$router.push(to.location);
        //   }
        // }
        // console.log(
        //   "********************* SWITCH DEFAULT ROUTE - LanguageSwitch ***********************"
        // );
        this.$router.push(to.location);

        // console.log("************ LANGUAGE prevLang", prevLang, prevPath, prevUrlData, newUrlData);
        });
      /*}else{
        console.log('else')
        switch(lang)
        {
          case 'en':
           window.location.href = `${process.env.VUE_APP_HDRUPAL_EN_URL}`;
            console.log(`${process.env.VUE_APP_HDRUPAL_EN_URL}`)
            break;
          case 'es':
            window.location.href = `${process.env.VUE_APP_HDRUPAL_ES_URL}`;
            console.log(`${process.env.VUE_APP_HDRUPAL_ES_URL}`)
            break;
          default:
            window.location.href = `${process.env.VUE_APP_HDRUPAL_ES_URL}`;
            break
        }
      }*/
      
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    }
  }
};
</script>

<style scoped lang="scss">
.select-lang {
  &.is-selected {
    display: none;
  }
}
.switch-box {
  display: inline-block;
  padding-right: 10px;
}
</style>

<template>
  <div>
    <div class="nav-bar-container">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar
          type="dark"
          variant="primary"
          class="d-none d-lg-block bg-base-green"
          id="top-menu"
          role="top-menu"
        >
          <b-navbar-nav class="ml-auto" align="right">
            <b-nav-item
              href="https://www.1firstbank.com/pr/es/centro-de-ayuda/localizador"
              class="c-white"
              >{{ $t("menuTop.locator.label") }}</b-nav-item
            >
            <b-nav-item
              href="https://www.1firstbank.com/pr/es"
              class="c-white"
              >{{ $t("menuTop.contact.label") }}</b-nav-item
            >
            <b-nav-item
              href="https://www.1firstbank.com/pr/es/sobre-nosotros/inversionistas"
              class="c-white"
              >{{ $t("menuTop.investor.label") }}</b-nav-item
            >
            <b-nav-text class="c-white">{{
              $t("menuTop.routing.label")
            }}</b-nav-text>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
    <div class="nav-bar-container bg-white">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar type="light" variant="faded" class="bg-white" id="main-menu">
          <b-navbar-brand :href="headerLink">
            <img
              src="../assets/images/logo.svg?v=1"
              class="d-inline-block align-top d-lg-block d-md-none desktop-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
            <img
              src="../assets/images/logo_mobile.png?v=2"
              class="d-inline-block align-top d-lg-none mobile-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-navbar-nav class="float-right navbar-right ml-auto language-nav">
            <LanguageSwitch />
          </b-navbar-nav>
          <b-navbar-nav class="back-menu-box" id="block-linkatras">
            <b-nav-form>
              <b-button href="https://1firstbank.com">
                1firstbank.com
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "./LanguageSwitch/Index";

export default {
  name: "SiteHeader",
  components: { LanguageSwitch },
  data: function() {
    return {
      language: this.$i18n.locale,
      headerLink: "/es",
      content: null,
      linksEs: {
        locator: "Locator",
        contact: "Contact Us",
        investor: "Investor Relations",
        routing: "Routing Number: 221571473"
      }
    };
  },
  methods: {
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
    }
  },
  watch: {
    $route() {
      this.updateLanguage();
      console.log("Language change");
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.site-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-link {
  border-right: 1px solid #fff;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Helvetica", Arial, sans-serif !important;
}
</style>

<template>
  <modal name="speedBump" height="auto">
    <div class="modal-dialog-speed-bump">
      <div class="modal-content-wrapper">
        <div class="modal-header">
          <button type="button" class="close" @click="$modal.hide('speedBump')">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="body-content">
            <p>
              {{ $t("modal.body_p_1_0") }} {{ urlModal }}.
              {{ $t("modal.body_p_1_1") }}
            </p>
          </div>
          <div class="body-footer">
            <a class="btn btn-open" :href="urlModal">Ok</a>
            <a class="btn btn-cancel" @click="$modal.hide('speedBump')">{{
              $t("modal.cancel")
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "speedBump",
  data() {
    return {
      urlModal: " "
    };
  },
  methods: {
    show() {
      this.$modal.show("speedBump");
    },
    hide() {
      this.$modal.hide("speedBump");
    },
    validateModalData(link, e, localThis) {
      if (
        link.className.includes("is-external") ||
        link.hasAttribute("data-external")
      ) {
        if (link.dataset) {
          let currentUri = link.dataset.uri;
          localThis.urlModal = currentUri;
          localThis.$modal.show("speedBump");
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      }
    },
    addModalJavascript() {
      const localThis = this;
      document.addEventListener("click", e => {
        if (e.target.localName === "a") {
          const link = e.target;
          localThis.validateModalData(link, e, localThis);
        }

        if (e.target.parentElement.localName === "a") {
          const link = e.target.parentElement;
          console.log("IM HERE 2", link);
          localThis.validateModalData(link, e, localThis);
        }
        if (e.target.parentElement.parentElement.localName === "a") {
          const link = e.target.parentElement.parentElement;
          console.log("IM HERE 3", link);
          localThis.validateModalData(link, e, localThis);
        }
      });
    }
  },
  mounted() {
    const routeThis = this;
    routeThis.addModalJavascript();
  },
  watch: {
    $route() {
      const routeThis = this;
      routeThis.addModalJavascript();
    }
  }
};
</script>

<style lang="scss">
.v--modal-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
.v--modal-background-click .v--modal-box {
  @media (max-width: 1024px) {
    width: 600px !important;
    left: calc(50% - 300px) !important;
    right: calc(50% - 300px) !important;
  }
  @media (max-width: 610px) {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  @media (max-width: 800px) {
    left: 10% !important;
    right: 10% !important;
    width: 80% !important;
  }
}
.modal-dialog-speed-bump {
  padding: 2% 5% 3%;
  overflow: hidden;
  width: 100%;

  .modal-header {
    padding: 0;
    border: none;
    display: block;
    width: 100%;
    float: right;

    .close {
      border: none;
      float: right;
      font-size: 21px;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      filter: alpha(opacity=20);
      opacity: 0.2;

      &:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        filter: alpha(opacity=50);
        opacity: 0.5;
      }
    }
  }

  .modal-body {
    margin: 0;
    padding: 0;
    width: 100%;
    clear: both;
    float: left;

    .body-content {
      padding: 0 0 10px;
    }

    p {
      font-family: Arial, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      word-break: break-word;
    }
  }

  .body-footer {
    margin: 0;
    padding: 0;
    text-align: right;

    .btn {
      font-family: Arial, sans-serif;
      font-weight: normal;
      border-radius: 2px;
      font-size: 20px;
      line-height: 1.25;
      display: inline-block;
      text-align: center;
      border: 0;
      user-select: none;
      vertical-align: middle;
      cursor: pointer;
      padding: 10px 29px 5px 29px;
      border: 1px solid transparent;
      outline: 0;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: #038459;
      color: #ffffff;
      box-shadow: none;
      display: inline-block;

      &:first-child {
        margin-right: 15px;
      }

      &:hover {
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
      }
    }
  }
}
</style>

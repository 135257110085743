import { API_ENDPOINTS } from "../utils/apiCollection";
import $http from "@/services/axios";

async function getMenus(lang) {
  const template = API_ENDPOINTS.CONTENT_TYPE;
  const contentURI = `/${lang}${API_ENDPOINTS.GET_MENU}`;
  let params = {
    jsonapi_include: "1",
    "filter[menu_type]": template
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getBlocks(lang) {
  const template = API_ENDPOINTS.CONTENT_TYPE;
  const contentURI = `/${lang}${API_ENDPOINTS.GET_BLOCK}`;
  let params = {
    jsonapi_include: "1",
    "filter[field_template]": template
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

export { getMenus, getBlocks };

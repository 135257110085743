<template>
  <div>
    <!-- Footer -->
    <b-container role="footer" class="footer-wrapper" fluid="flex">
      <div class="footer footer-bottom container-lg">
        <div class="region region-footer-bottom">
          <section class="copyright-block block">
            <div class="body body-footer">
              <div class="footer__bar1">
                <p class="copyright" v-if="language === 'es'">
                  Copyright © 2022 First BanCorp. Todos los derechos reservados.
                  Solo para residentes de Puerto Rico.
                  <br />
                  Notificación de apertura de cuentas:<br />
                  Las leyes federales requieren que toda institución financiera
                  obtenga, verifique y conserve en sus expedientes cierta
                  información sobre la identificación de aquellas personas que
                  abran cuentas en la institución. Esto significa que, para toda
                  cuenta que desee abrir le requeriremos, su nombre, dirección,
                  fecha de nacimiento, número de seguro social y otra
                  información que nos permita identificarlo. Además, debe
                  mostrarnos su licencia de conducir u otros documentos de
                  identificación oficial. En todo caso, es nuestro compromiso
                  proteger la identidad y la confidencialidad de la información
                  de nuestros clientes.
                  <br>
                  <a class="privacy" href="https://www.1firstbank.com/pr/es/Documents/FirstBank_Politica_de_Privacidad.pdf">Política de Privacidad</a>
                </p>
                <p class="copyright" v-else>
                 Copyright © 2022 First BanCorp. All rights reserved.
                  <br />
                 Opening Account Notice:  <br />
                  Federal Law requires that all financial institutions obtain, verify, 
                  and keep records regarding the information that identifies all persons 
                  who open accounts. This means that when you open an account, we will ask for your name, address, date of birth and social security or tax identification number as well as other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents. In all cases, protection of our customers’ identity and confidentiality of their information is our commitment.<br> 
                <a class="privacy" href="https://www.1firstbank.com/pr/en/Documents/FirstBank_Privacy_Policy%20(1).pdf">Privacy Policy</a>
                </p>
                
              </div>
            </div>
          </section>
          <section class="footer-icon-block block">
            <div class="field field--name-body field--type-text-with-summary">
              <p>
                <img
                  role="img"
                  alt="fdic"
                  aria-label="fdic"
                  srcset="@/assets/images/logo3.png"
                  src="@/assets/images/logo3.png"
                />
                <img
                  role="img"
                  alt="Logo Member FDIC"
                  aria-label="Logo Member FDIC"
                  srcset="@/assets/images/logo1.png"
                  src="@/assets/images/logo1.png"
                />
                <img
                  role="img"
                  alt="fdic"
                  aria-label="fdic"
                  srcset="@/assets/images/logo2.png"
                  src="@/assets/images/logo2.png"
                />
              </p>
            </div>
          </section>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
  data() {
    return {
      templateName: "Footer",
      language: this.$i18n.locale
    };
  },
  components: {},
  computed: {},
  methods: {},
  watch: {
    $route() {
      this.language = this.$i18n.locale;
    }
  }
};
</script>
<style scoped lang="scss">
.field--name-body {
  text-align: left;
}
.footer-wrapper .footer-bottom {
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>

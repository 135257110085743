import store from "@/store";
export default {
  cleanPhone(phone) {
    const newPhone = phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    // console.log("New phone", newPhone);
    return newPhone;
  },
  getBranchsVi: function() {
    return [
      'Waterfront',
      'Port of Sale',
      'FirstBank Plaza',
      'Marketplace -St. John',
      'Sunny Isle',
      'Orange Grove',
      'Frederiksted',
      'Road Town',
    ];
  },
  getBranchs: function() {
    return [
      "Aguada",
      "Aguadilla",
      "Arecibo - Carretera #20",
      "Arecibo - Ave. José Cedeño",
      "Ave. 65 de Infantería",
      "Bayamón Oeste",
      "Bayamón - Drive-In",
      "Bayamón - Forest Hills",
      "Bayamón - Plaza del Parque",
      "Bayamón - Rexville",
      "Bayamón - Santa Rosa Mall",
      "Cabo Rojo",
      "Caguas Sur",
      "Calle Loíza",
      "Canóvanas - The Outlet Mall 66",
      "Cayey",
      "Condado - Ave. Ashford Esq. Calle Condado",
      "Condado - Ave. Ashford Esq. Ave. De Diego",
      "Condado - Plazoleta del Condado",
      "De Diego - Río Piedras",
      "Dorado",
      "El Señorial",
      "Fajardo",
      "Galería Los Paseos",
      "Guayama",
      "Guaynabo - Carretera 199",
      "Guaynabo - Plaza Guaynabo",
      "Humacao Plaza",
      "Isla Verde",
      "Las Catalinas Mall - Ave. Zafiro",
      "Las Catalinas Mall - Carretera 156",
      "Levittown",
      "Manatí",
      "Mayagüez - Western Plaza",
      "Mayagüez Mall",
      "Montehiedra",
      "Muñoz Rivera",
      "Parque Escorial",
      "Piñero",
      "Plaza Carolina",
      "Plaza Del Caribe",
      "Plaza Del Sol",
      "Plaza Las Américas",
      "Ponce De León - Hato Rey/Milla de Oro",
      "Ponce de León - Santurce",
      "Ponce - Coto Laurel",
      "San Francisco",
      "San Patricio",
      "San Sebastián ",
      "Toa Alta - Plaza Aquarium",
      "Trujillo Alto",
      "Vega Baja",
      "Viejo San Juan",
      "Yauco"
    ];
  },
  getTownsVi() {
    return [
      'St. Thomas',
      'St. Croix',
      'St. John',
      'Tortola',
    ];
  },
  getFullImagePath(uri) {
    if (uri) {
      return `${process.env.VUE_APP_HDRUPAL_BASE_URL}${uri}`;
    }

    return null;
  },
  setTextAreaField(field) {
    if (!field) {
      return null;
    }

    return field.value;
  },
  getMenuUrl(uri, lang) {
    if(uri === "route:<nolink>"){
      return false;
    }
    let cleanURI = uri.replace("internal:/es/", "/es/");
    if (uri.includes("internal:/es/")) {
      cleanURI = uri.replace("internal:/es/", "/es/");
      return cleanURI;
    }
    if (uri.includes("internal:/en/")) {
      cleanURI = uri.replace("internal:/en/", "/en/");
      return cleanURI;
    }
    if (uri.includes("internal:#")) {
      cleanURI = uri.replace("internal:#", `#`);
      return cleanURI;
    }
    if (uri.includes("internal:/")) {
      cleanURI = uri.replace("internal:/", `/${lang}/`);
      return cleanURI;
    }

    return uri;
  },
  getContactTimeList() {
    return [
      'Morning: 8:30 a.m. – 12:00 p.m.',
      'Afternoon: 12:00 p.m. – 5:00 p.m.',
    ];
  },
  getTowns() {
    return [
      "Adjuntas",
      "Aguada",
      "Aguadilla",
      "Aguas Buenas",
      "Aibonito",
      "Añasco",
      "Arecibo",
      "Arroyo",
      "Barceloneta",
      "Barranquitas",
      "Bayamón",
      "Cabo Rojo",
      "Caguas",
      "Camuy",
      "Canóvanas",
      "Carolina",
      "Cataño",
      "Cayey",
      "Ceiba",
      "Ciales",
      "Cidra",
      "Coamo",
      "Comerío",
      "Corozal",
      "Culebra",
      "Dorado",
      "Fajardo",
      "Florida",
      "Guánica",
      "Guayama",
      "Guayanilla",
      "Guaynabo",
      "Gurabo",
      "Hatillo",
      "Hormigueros",
      "Humacao",
      "Isabela",
      "Jayuya",
      "Juana Díaz",
      "Juncos",
      "Lajas",
      "Lares",
      "Las Marías",
      "Las Piedras",
      "Loíza",
      "Luquillo",
      "Manatí",
      "Maricao",
      "Maunabo",
      "Mayagüez",
      "Moca",
      "Morovis",
      "Naguabo",
      "Naranjito",
      "Orocovis",
      "Patillas",
      "Peñuelas",
      "Ponce",
      "Quebradillas",
      "Rincón",
      "Río Grande",
      "Sabana Grande",
      "Salinas",
      "San Germán",
      "San Juan",
      "San Lorenzo",
      "San Sebastián",
      "Santa Isabel",
      "Toa Alta",
      "Toa Baja",
      "Trujillo Alto",
      "Utuado",
      "Vega Alta",
      "Vega Baja",
      "Vieques",
      "Villalba",
      "Yabucoa",
      "Yauco"
    ];
  }
};
